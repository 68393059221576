.login-panel {
  text-align: center;
  background: #fff;
  display: inline-block;
  border: 1px solid #ddd;
  box-shadow: 0 0 7px -5px #000;
}

.login-panel__heading {
  color: #212121;
  background: #f7f7f7;
  border-bottom: 1px solid #ddd;
  padding: 30px 15px;
}

.login-panel__body {
  padding: 0 15px 40px 15px;
}

.login-panel__logo {
  width: 150px;
}

.login-panel__heading, .login-panel__logo, .login-panel__username, .login-panel__password {
  margin-bottom: 15px;
}
