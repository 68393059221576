:root {
  --theme-primary: #016187;
  --theme-secondary: #018786;
  --theme-secondary-light: #d1e1e1;
  --main-content-vertical-padding: 20px;
  --main-content-side-padding: 15px;
}

body {
  background: #eee;
}

#main-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
  padding: var(--main-content-vertical-padding) var(--main-content-side-padding);
}
