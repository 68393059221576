.timers {
  display: flex;
}

.timers > div {
  flex: 1;
  margin: 0 10px;
  text-align: center;
}

.timers .mdc-typography {
  font-size: 2.5rem;
}

.timers .text .mdc-typography {
  font-size: 1rem;
}
