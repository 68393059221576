:root {
    --mdc-theme-primary: var(--theme-primary);
    --mdc-theme-secondary: var(--theme-secondary);
}

@import '@material/react-material-icon/dist/material-icon.css';
@import '@material/react-list/dist/list.css';
@import '@material/react-typography/dist/typography.css';
@import '@material/react-text-field/dist/text-field.css';
@import "@material/react-menu-surface/dist/menu-surface.css";
@import "@material/react-menu/dist/menu.css";
@import '@material/react-select/dist/select.css';
@import '@material/react-fab/dist/fab.css';
@import '@material/react-linear-progress/dist/linear-progress.css';
@import '@material/react-button/dist/button.css';
@import '@material/react-dialog/dist/dialog.css';

/* MDCSelect & MDCTextField currently uses a hardcoded value, but we can just override it.
 *  See https://github.com/material-components/material-components-web/issues/7629
 *      https://github.com/material-components/material-components-web/issues/7464
 */
.mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label,
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: var(--mdc-theme-primary, #000);
}
