.generator-results-page {
  // Re-expand the page to fill the viewport (undo the padding from #main-content), because we want the background to fill the viewport
  margin: calc(-1 * var(--main-content-vertical-padding)) calc(-1 * var(--main-content-side-padding));
  padding: var(--main-content-vertical-padding) var(--main-content-side-padding);
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg width='84' height='48' viewBox='0 0 84 48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h12v6H0V0zm28 8h12v6H28V8zm14-8h12v6H42V0zm14 0h12v6H56V0zm0 8h12v6H56V8zM42 8h12v6H42V8zm0 16h12v6H42v-6zm14-8h12v6H56v-6zm14 0h12v6H70v-6zm0-16h12v6H70V0zM28 32h12v6H28v-6zM14 16h12v6H14v-6zM0 24h12v6H0v-6zm0 8h12v6H0v-6zm14 0h12v6H14v-6zm14 8h12v6H28v-6zm-14 0h12v6H14v-6zm28 0h12v6H42v-6zm14-8h12v6H56v-6zm0-8h12v6H56v-6zm14 8h12v6H70v-6zm0 8h12v6H70v-6zM14 24h12v6H14v-6zm14-8h12v6H28v-6zM14 8h12v6H14V8zM0 8h12v6H0V8z' fill='%23f8f8f8' fill-opacity='1' fill-rule='evenodd'/%3E%3C/svg%3E");
  flex: 1;
  display: flex;
  flex-direction: column;
}

.generator-results-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.results-heading {
  text-align: center;
  margin: 2em 0;
  margin-top: calc(2em - var(--main-content-vertical-padding));
}

.results-heading__title {
  color: var(--theme-secondary);
  margin: 0;
}

.results-heading__league-name {
  color: #797979;
  margin: 0;
}

.results-heading__share-page {
  margin-top: 2rem;

  .mdc-text-field.mdc-text-field--outlined {
    background-color: #fff;
  }
}

.generating-results {
  color: var(--theme-secondary);
  text-align: center;
}

.progress-bar-container {
  box-sizing: border-box;
  width: 100%;
  max-width: 600px;
  background-color: #fff;
  padding: 1.5em;
  margin-bottom: 1em;
  border: 1px solid #e0e0e0;
  border-radius: 30px;
  box-shadow: 3px 3px 3px -3px #000, -3px -3px 3px -3px #fff;
}

.progress-bar {
  margin: 0;
  height: 8px;
  border-radius: 4px;
}

.results-teams-container {
  display: flex;
  flex-direction: column;
}

.show-teams-button-container {
  text-align: center;
}

.show-teams-dialog__title {
  padding: 1.5em 2em;
}

@media screen and (min-width: 540px) {
  .show-teams-dialog__content {
    min-width: 500px;
  }
}

.show-teams-dialog__teams-container {
  text-align: left;
}

.show-teams-dialog__team {
  display: flex;
  align-items: center;
  padding: 0.8em 0;
  &:not(:last-child) {
    border-bottom: 1px solid var(--theme-secondary-light);
  }
}

.show-teams-dialog__team-name {
  color: var(--theme-secondary);
}

.show-teams-dialog__footer {
  background-color: var(--theme-secondary-light);
  border: none;
}

.countdown-to-text {
  flex: 1;
  text-align: center;
  margin-bottom: 2.5rem;
  color: var(--theme-primary);
  font-weight: 500;
}

.result-items-container {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
}

.result-items-container .result-item:not(:last-child) {
  margin-bottom: 1rem;
}
