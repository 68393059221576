.result-item {
  padding: 1rem;
  border-radius: 20px;
  background-color: var(--theme-secondary-light);
  text-align: center;
  position: relative;
  display: none;
}

.result-item--showing {
  display: block;
}

.result-item__pick-number {
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-size: 0.9rem;
  color: #94b3b3;
  font-weight: 700;
}

.result-item__team-name {
  margin: 0;
  padding: 0;
  padding-top: 0.5rem;
  font-weight: 500;
  color: var(--theme-secondary);
  font-size: 1.5rem;
}
