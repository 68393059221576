.share-page {
  display: flex;
  align-items: center;
  justify-content: center;
}

.share-page__input {
  &.mdc-text-field__input { caret-color: transparent; }
}

.share-page__icon {
  margin-left: 1rem;
  cursor: pointer;
  color: #8f8f8f;
}

.share-page__icon--copied {
  color: var(--theme-primary);
}
