.nav-header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.nav-header__logo {
  flex: 1;
  img {
    width: 80px;
  }
}

.nav-header__link {
  color: #5d5d5d;
  text-decoration: none;
  padding: 1rem;
}

