.no-match {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  text-align: center;
  position: relative;
  overflow: hidden;
  background: linear-gradient(110deg, #f5f5f5 50%, #fff 50%)
}

.no-match__logo {
  max-width: 100px;
  margin: 0.5em auto;
}

.no-match__heading {
  padding: 0;
  margin: 0 0 0.5em;
  color: var(--theme-primary);
}

.no-match__subheading {
  padding: 0;
  margin: 0 0 1.5em;
}

.no-match__suggested-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 1rem;
}
