.slide-up-content__heading {
  background-color: var(--theme-primary);
  background-image: url("data:image/svg+xml,%3Csvg width='84' height='48' viewBox='0 0 84 48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h12v6H0V0zm28 8h12v6H28V8zm14-8h12v6H42V0zm14 0h12v6H56V0zm0 8h12v6H56V8zM42 8h12v6H42V8zm0 16h12v6H42v-6zm14-8h12v6H56v-6zm14 0h12v6H70v-6zm0-16h12v6H70V0zM28 32h12v6H28v-6zM14 16h12v6H14v-6zM0 24h12v6H0v-6zm0 8h12v6H0v-6zm14 0h12v6H14v-6zm14 8h12v6H28v-6zm-14 0h12v6H14v-6zm28 0h12v6H42v-6zm14-8h12v6H56v-6zm0-8h12v6H56v-6zm14 8h12v6H70v-6zm0 8h12v6H70v-6zM14 24h12v6H14v-6zm14-8h12v6H28v-6zM14 8h12v6H14V8zM0 8h12v6H0V8z' fill='%23000000' fill-opacity='0.06' fill-rule='evenodd'/%3E%3C/svg%3E");
  box-sizing: border-box;
  height: var(--slide-up-content-header-height);
  width: 100%;
  padding-top: 60px;
  padding-left: var(--main-content-side-padding);
  padding-right: var(--main-content-side-padding);
  text-align: center;
  position: fixed;
  box-shadow: 0 -5px 5px -5px #000 inset;
}
.slide-up-content__heading-logo {
  width: 120px;
  height: 120px;
}
.slide-up-content__heading-tagline {
  color: #fff;
  font-family: 'system-ui';
  margin-top: 2rem;
}
.slide-up-content__heading-advertising {
  background-color: var(--theme-primary);
  width: 180px;
  height: 150px;
  box-shadow: 0 0 5px -2px #000;
  display: none;
}
.slide-up-content__heading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  display: none;
  opacity: 0;
}
