#mdc-dialog-title {
  text-align: center;
}

.schedule-dialog-input__date, .schedule-dialog-input__time {
  -webkit-appearance: none;
  appearance: none;
  padding: 1rem;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-family: 'Roboto', sans-serif;
  color: #333;
  background-color: #fff;
  box-sizing: border-box;
  width: 100%;

  &:hover {
    border-color: #aaa;
  }
}

.schedule-dialog-input__time {
  margin-top: 1rem;
}

.schedule-dialog-input__helper-text {
  margin: 0;
  color: #d16767;
  font-size: 0.8rem;
  padding-left: 1rem;
}
