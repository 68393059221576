.simple-generator {
  max-width: 1200px;
  margin: 0 auto;
}
.simple-generator__generator-input-actions {
  grid-area: actions;
}
.simple-generator__generator-section-heading-league {
  grid-area: heading-league
}
.simple-generator__generator-input-league-name {
  grid-area: league-name;
}
.simple-generator__generator-input-number-teams {
  grid-area: number-teams;
}
.simple-generator__generator-section-heading-teams {
  grid-area: heading-teams;
}
.simple-generator__generator-teams {
  grid-area: teams;
}
.simple-generator__generator {
  display: grid;
  grid-template-areas:
    "actions"
    "heading-league"
    "league-name"
    "number-teams"
    "heading-teams"
    "teams";
  grid-column-gap: 2rem;
}
@media screen and (min-width: 1000px) {
  .simple-generator__generator {
    grid-template-areas:
      "league-name    actions        number-teams"
      "heading-teams  heading-teams  heading-teams"
      "teams          teams          teams";
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
  }
  .simple-generator__generator-input-league-name {
    max-width: 400px;
  }
  .simple-generator__generator-input-number-teams {
    max-width: 400px;
    width: 100%;
    justify-self: end;
  }
  .simple-generator__generator-section-heading-league.simple-generator__generator-section-heading-league {
    display: none;
  }
}

.heading-content {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
}
.heading-content__heading {
  font-weight: 700;
}
.heading-content__subheading {
  font-weight: 300;
  font-size: 1.2rem;
}
.heading-content__description {
  margin-top: 2rem;
}

.simple-generator__app-content {
  background-color: #eee;
  margin-left: calc(var(--main-content-side-padding) * -1);
  margin-right: calc(var(--main-content-side-padding) * -1);
  padding-left: var(--main-content-side-padding);
  padding-right: var(--main-content-side-padding);
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
}

.simple-generator__demo-link {
  text-decoration: none;
  align-self: flex-end;
  margin: 1rem 0;
  @media screen and (min-width: 1000px) {
    margin: 2rem 0;
  }
}

.simple-generator__generator-input-actions {
  position: fixed;
  bottom: 1rem;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 5;
}
@media screen and (min-width: 1000px) {
  .simple-generator__generator-input-actions {
    position: initial;
    margin-bottom: 15px;

    button {
      width: 100%;
      max-width: 600px;
    }
  }
}

.simple-generator__generator-execute-button {
  --mdc-theme-secondary: #fff;
  --mdc-theme-on-secondary: var(--theme-secondary);
}

.simple-generator__generator-input-league-name,
.simple-generator__generator-input-number-teams {
  .mdc-text-field, .mdc-select { display: block; }

  margin-bottom: 15px;
}
.simple-generator__generator-section-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--theme-secondary);
  padding: 0 1rem;
  background-color: var(--theme-secondary-light);
  margin-bottom: 1rem;
}
.simple-generator__generator-section-heading-teams {
  margin-top: 2rem;
}
.simple-generator__generator-teams {
    .mdc-text-field { display: block; }
}
@media screen and (min-width: 400px) {
  .simple-generator__generator-teams {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-column-gap: 10px;
  }
}
.simple-generator__generator-team-input {
  .mdc-text-field { display: block; }
  .mdc-text-field-helper-text { display: none; }

  margin-bottom: 15px;
}

