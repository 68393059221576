.contact {
  background-color: var(--theme-secondary-light);
  padding: 4rem 2rem 6rem;
  border-radius: 10px;
  text-align: center;
}

.contact__heading {
  margin: 0;
  color: var(--theme-primary);
  font-size: 2rem;
  @media screen and (min-width: 600px) {
    font-size: 2.5rem;
  }
}

.contact__subheading {
  margin: 1rem 0;
  color: #323131;
  font-weight: 300;
  @media screen and (min-width: 600px) {
    font-weight: normal;
  }
}

.contact__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 0 auto;
  margin-top: 4rem;
  max-width: 1000px;
  text-align: left;
}

.contact-item:not(:last-child) {
  margin-bottom: 4rem;
}

.contact-item__icon {
  color: #777;
}

.contact-item__heading {
  margin: 0.5rem 0;
  font-size: 1.3rem;
}

.contact-item__link {
  text-decoration: none;
}

.contact__screenshot {
  width: 600px;
  max-width: 100%;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin: 0 auto;
}

.footer {
  text-align: left;
  color: #797979;
  margin: 1.5rem 0;
  font-size: 0.8rem;
}
